// // /--------------------------------------
// // /--------------------------------------
// // /--------------------------------------
// let intro = document.getElementById("intro");
// let yakai = document.getElementById("yakai");
// let natuyakai = document.getElementById("natuyakai");
// let logoNatuyakai = document.getElementById("logoNatuyakai");
// // console.log( logoNatuyakai );
// let natuLogotype = document.getElementsByClassName("natu_logotype");
// let logotypeNatuArr = [];
// for ( i = 0; i < natuLogotype.length; i++ ) {
//   logotypeNatuArr.push( natuLogotype[i] )
// }
//
//
// // /--------------------------------------
// window.onload = function() {
//   function logoYakaiShow()
//   {
//     return new Promise( function(resolve)
//     {
//       setTimeout( function()
//       {
//         // yakai.classList.add("logoYakaiShow");
//         resolve();
//       }, 0);
//     });
//   }
//
//   logoYakaiShow()
//   .then( function (resolve)
//   {
//     return new Promise( function(resolve)
//     {
//       setTimeout( function()
//       {
//         // yakai.classList.remove("logoYakaiShow");
//         resolve();
//       }, 0 );
//     })
//   })
//   .then( function (resolve)
//   {
//     return new Promise( function(resolve)
//     {
//       setTimeout( function()
//       {
//         natuyakai.classList.add("natuyakaiShow");
//         resolve();
//       }, 1000 );
//     })
//   })
//   .then( function (resolve)
//   {
//     return new Promise( function(resolve)
//     {
//       setTimeout( function()
//       {
//         logotypeNatuArr[0].classList.add("logoNatuShow");
//         logotypeNatuArr[1].classList.add("logoNatuShow");
//         logotypeNatuArr[2].classList.add("logoNatuShow");
//         logotypeNatuArr[3].classList.add("logoNatuShow");
//         resolve();
//       }, 1000 );
//     })
//   })
//   .then( function (resolve)
//   {
//     return new Promise( function(resolve)
//     {
//       setTimeout( function()
//       {
//         intro.classList.add("introHide");
//         sliderStart();
//         $("body,html").animate({scrollTop: 0}, 0);
//         resolve();
//       }, 2000 );
//     })
//   })
// }
//
//
//
//
//
//
//
// // /--------------------------------------
// // /--------------------------------------
// // /--------------------------------------
// const slide = document.getElementById("sliderBox");      //スライダー親
// const slideItem = slide.querySelectorAll(".img");   //スライド要素
// const totalNum = slideItem.length - 1;              //スライドの枚数を取得
// const fadeTime = 5000;                              //フェードインの時間
// const intarvalTime = 4500;                       //クロスフェードさせるまでの間隔
// let actNum = 0;                                     //現在アクティブな番号
// let nowSlide;                                       //現在表示中のスライド
// let nextSlide;                                      //次に表示するスライド
//
// window.addEventListener("load", function () {
//    // sliderStart();
//    // slideItem[0].classList.add("slideShow", "slideZoom");
// });
// //
// function sliderStart() {
//
//     // スライドの1枚目をフェードイン
//     slideItem[0].classList.add("slideShow", "slideZoom");
//
//     setInterval( function(){
//         if (actNum < totalNum) {
//
//             let nowSlide = slideItem[actNum];
//             let nextSlide = slideItem[++actNum];
//             // let nextSlide = slideItem[actNum] + 1;
//
//             nowSlide.classList.remove("slideShow");
//             nextSlide.classList.add("slideShow", "slideZoom");
//             setTimeout( function(){
//                 nowSlide.classList.remove("slideZoom");
//             }, fadeTime);
//
//         } else {
//
//             let nowSlide = slideItem[actNum];
//             let nextSlide = slideItem[actNum = 0];
//             nowSlide.classList.remove("slideShow");
//             nextSlide.classList.add("slideShow", "slideZoom");
//             setTimeout( function(){
//                 nowSlide.classList.remove("slideZoom");
//             }, fadeTime);
//
//         };
//     }, intarvalTime);
//
// }
